import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import CalendlyButton from './CalendlyButton';

function TransformSection() {
  return (
    <div >
    <Container className="transform-section">
      <Row>
        <Col><h1 className='gradient-text'>It's time to transform your business</h1></Col>
      </Row>
      <Row>
        <Col ><p className='white-text'>In a world where consumer expectations are ever-changing, your ability to engage and adapt is essential for success.</p><p className='white-text'> Our AI-powered solutions empower you to not only meet but surpass those expectations, raising the bar for your industry.</p><p className='white-text'> Hesitation comes at a cost—every moment delayed is a lost opportunity. The future won't pause, and neither should you. </p></Col>
      </Row>
      {/* <Button variant="primary">Schedule a Call</Button> */}
      
      <CalendlyButton calendlyUrl="https://calendly.com/jazzmarketingsolutions"/>
      
    </Container>
    </div>
  );
}

export default TransformSection;