
import Aws from '../assets/aws.png';
import Adobe from '../assets/adobe.svg';
import googlecloud from '../assets/googlecloud.png';
import openai from '../assets/openai.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SEO from '../assets/seo.webp';
import AI from '../assets/ai.webp';


import ServicesCard from "./ServicesCard";
function TechCompany(){
    const deleteMe = "Some quick example text to build on the card title and make up the bulk of the card's content."
    const companies = [
        {imageSource: Adobe},
        {imageSource: Aws},

        {imageSource: openai},
        {imageSource: googlecloud}
    ]

    return(
        <Container className='techcompany'>
            <Row>
                <h1 className='gradient-text'>Services we rely on</h1>
            </Row>
            <Row   className="services-row">
            {companies.map((s) => {
                return (
                    <Col>
                    <img className="companybrands" src={s.imageSource}/>
                    </Col>
                )
            })}

            </Row>
        </Container>
    )
}

export default TechCompany;