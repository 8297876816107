import logo from './logo.svg';
import './App.css';
import { Link, Routes, Route } from 'react-router-dom';


import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import CalendlyButton from './components/CalendlyButton';
import Home from './components/Home';
import Pricing from './components/Pricing';
import Website from './components/Website';
function App() {
  
  return (
    <div className="App">
        <Navbar expand="lg" className="navmenu" fixed="top">
        <Container>
          <Navbar.Brand href="#home">Web Strategy Solutions</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              {/* <Button variant="light">Schedule a Call</Button> */}
              <CalendlyButton calendlyUrl="https://calendly.com/jazzmarketingsolutions"/>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/pricing' element={<Pricing/>} />
        <Route path='/website' element={<Website/>} />
        <Route path='/links' element={<Website/>} />
        <Route path='/websites' element={<Website/>} />
      </Routes>
    </div>
  );
}

export default App;
