function Website(){
    const links = [
       { title: "Acai", link: "https://wss-acai-restaurant.vercel.app/"},
       {  title: "Brazillian Muscle Bowl", link:  "https://brazillian-muscle-bowl.vercel.app/"},
       { title: "Boba", link:  "https://wss-boba-restaurant.vercel.app/"},
       { title: "Dentist", link:  "https://wss-dentist-website.vercel.app/"},
       { title: "Donut", link:  "https://wss-donut-restaurant.vercel.app/"},
       { title: "Eye clinic", link:  "https://wss-eyeclinic.vercel.app/"},
       { title: "Mediterranean", link:  "https://wss-mediterranean-website.vercel.app/"},
       { title: "Mexican", link:  "https://wss-mexican-restaurant.vercel.app/"},
       { title: "Steakhouse", link: "https://wss-steakhouse-restaurant.vercel.app/"},
       { title: "Sushi", link:  "https://wss-sushi-restaurant.vercel.app/"},
       { title: "Tattoo Studio", link:  "https://wss-tattoo-studio.vercel.app/"},
       { title: "Tattoo Studio Light", link:  "https://wss-tattoostudios2.vercel.app"}
    ]
    return(
        <div style={{padding: '200px'}}>
            {links.map((link)=> {
                return (
                    <div><a target="_blank" style={{fontSize: '20px', color: 'white'}} href={link.link}>{link.title}</a></div>
                )
            })}
        </div>
    )


}

export default Website;




