import { TypeAnimation } from 'react-type-animation';

function Spelling(){
    return(
        <TypeAnimation
            className='spelling'
            sequence={[
                // Same substring at the start will only be typed once, initially
                'For your Dental Clinic',
                1000,
                'For your Logistics Website',
                1000,
                'For your Gym',
                1000,
                'For your Law firm',
                1000,
                'For your Grocery Store',
                1000,
                'For your Ecommerce Site',
                1000,
                'For your Healthcare company',
                1000,
                'For your Energy Company',
                1000,
            ]}
            speed={30}
            style={{ fontSize: '2em', color: 'white' }}
            repeat={Infinity}
            />
    )
}
export default Spelling;