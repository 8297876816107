// import CalendlyButton from "./CalendlyButton";
// function Pricing(props){
//     return(
//         <div className='services-page container my-5'>
            
//                 {/* <div className='row mb-5'>
//                    <img src={InvisalignImage}/> 
//                 </div> */}
//                 <div className='row'>
//                                 <div className='col-lg-12 d-flex flex-column justify-content-center'>
//                                 <div>
//                                     <h2 className='mb-5  fw-bold  great-vibes-regular'>Pricing</h2>
                                   
                                        // <ul>
                                        //     <li>Design</li>
                                        //     <li>Hosting</li>
                                        //     <li>5 pages included</li>
                                        //     <li>24/7 Support</li>
                                        //     <li>1 Free Monthly Update</li>
                                        //     <li>$100 fee Per Page after 5</li>
                                        // </ul>
//                                 </div>
//                                 </div> 
//                 </div>
//                 <div className='row'>
//                     <CalendlyButton calendlyUrl="https://calendly.com/jazzmarketingsolutions"/>

//                     </div>
//                 </div>
//     )
// }

import Card from 'react-bootstrap/Card';
import CalendlyButton from "./CalendlyButton";

function Pricing(props) {
  const {title, description, imageSource} = props;
  return (
    <div className='services-page container my-5'>
        <div className='row'>
            <div className='col-lg-12 d-flex flex-column justify-content-center h-1200'>
            <div>
                <Card  className='servicecard h-1200'>
                {/* <Card.Img variant="top" src={imageSource} /> */}
                <Card.Body>
                    <Card.Title className='gradient-text'>Pricing</Card.Title>
                    <Card.Text>
                        <ul>
                            <li><b>What's included</b></li>
                            <li>Design & Development</li>
                            <li>Hosting</li>
                            <li>5 pages included</li>
                            <li>24/7 Support</li>
                            <li>1 Free Monthly Update</li>
                            <li>Calendly Setup and Integration</li>
                            <li>Google Maps Integration</li>
                            <li>Integration with existing social media: Facebook, Instagram, Yelp</li>
                            <li>Setup with existing Point of Sale system</li>
                            
                            {/* <li><h2>$1000 for 1 year</h2></li>
                            <li>or <h2>$100/month for 6 months</h2></li> */}
                        </ul>
                    </Card.Text>
                    <CalendlyButton calendlyUrl="https://calendly.com/jazzmarketingsolutions"/>
                </Card.Body>
                </Card>
                </div>
            </div>

            <div className='col-lg-12 d-flex flex-column justify-content-center'>
            <div>
                <Card  className='servicecard h-1200'>
                {/* <Card.Img variant="top" src={imageSource} /> */}
                <Card.Body>
                    <Card.Title>Upgrades</Card.Title>
                    <Card.Text>
                        <ul>
                            <li>$100 for each additional page (content only static page)</li>
                            <li>$100 for custom branding including Logo</li>
                            <li>$100 for Blog integration </li>
                            <li>$300 CRM Setup & Integration</li>
                            <li>$300 Payment Processing Integration</li>
                            <li>$100/monthly for AI Chatbot</li>
                            <li>$100 for Copyrighting</li>

                        </ul>
                    </Card.Text>
                </Card.Body>
                </Card>
                </div>
            </div>


        </div>
    </div>
  );
}


export default Pricing;