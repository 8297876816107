import HeroSection from './HeroSection';
import Services from './Services';
import TransformSection from './TransformSection';
import TechCompany from './TechCompany';

function Home() {
  
  return (
    <div className="">
        <HeroSection/>
        <Services/>
        <TechCompany/>
        <TransformSection/>
    </div>
  );
}

export default Home;
